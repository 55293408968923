<template>
    <section id="section-home">
        <!--<div id="main-bg-image" class="bgimg"
             :style="`background-image: url(` + require('@/assets/images/home/bg2.jpg') + `)`"></div>
             -->
        <div class="panel-tt">
            <div class="panel-tt2">
                <img src="@/assets/images/home/tt3.jpg"/>
            </div>
        </div>
        <div class="panel-outer">
            <div class="panel">
                <div class="panel2">
                    <!--
                    <div class="l1 style1 stagger1">From the director of ANNABELLE</div>

                    <div class="tt stagger1"><img src="@/assets/images/home/tt.png"/></div>
                    -->
                    <!--
                    <div class="topdate1 stagger1">

                    </div>

                  -->

                    <div v-if="dateMode===1" class="topdate2 stagger1">
                        NOW PLAYING
                    </div>

                    <div v-if="dateMode===2" class="topdate2a stagger1">
                        ON DIGITAL
                    </div>
                    <div v-if="dateMode===2" class="topdate2 stagger1">
                        MAY 16
                    </div>

                    <div v-if="dateMode===3" class="topdate2a stagger1">
                        NOW AVAILABLE
                    </div>
                    <div v-if="dateMode===3" class="topdate2 stagger1">
                        ON DIGITAL
                    </div>

                    <div class="gettix stagger1">
                        <div class="l-small">
                            <button @click="openAction">
                                <span v-if="dateMode!==3">PRE-ORDER NOW</span>
                                <span v-if="dateMode===3">WATCH NOW</span>
                            </button>
                        </div>
                    </div>

                    <button class="ytplayer stagger1" @click="gh.openOverlay('video')">
                        <div class="bg">
                            <img src="@/assets/images/home/player.jpg"/>
                        </div>
                        <div class="icon stagger1">
                            <div class="icon2">
                                <inline-svg :src="require('@/assets/images/home/btn-play.svg')"/>
                            </div>
                        </div>
                    </button>

                    <div class="l3 style2 signup stagger1">SIGN UP FOR UPDATES</div>
                    <div class="signup stagger1">
                        <EmailSignup
                            mcDomain="https://welcomevillain.us20.list-manage.com"
                            mcUserId="b25b645d846865015940afb4d"
                            mcListId="f713ffe268"
                        ></EmailSignup>
                    </div>

                    <div class="btn-outer stagger1">
                        <a href="https://www.dropbox.com/sh/ryfbpb50f32vwq7/AACjbwnRVSueZCq6vVPKKb6Ma?dl=0" target="_blank" rel="noreferrer" >
                            <button class="btn">
                                <span>PRESS/EPK</span>
                            </button>

                        </a>
                    </div>

                    <!--
                  <div class="hashtag stagger1">#LilithIsHere</div>
                  -->
                </div>
            </div>
        </div>
        <div id="order" :class="[(isOpen) ? 'open' : '']">
            <div @click="closeAction" class="scrim-closer"></div>
            <div class="order-inner">
                <div class="order-inner2 twocol">
                    <div class="tt-wide">
                        <img src="@/assets/images/home/tt-wide.png"/>
                    </div>
                    <div v-if="dateMode===1"  class="bugs">
                        <button class="bug">
                            <a href="https://www.fandango.com/malum-2023-231368/movie-overview?date=2023-03-30" target="_blank">
                                <img src="@/assets/images/order/fandango.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.regmovies.com/movies/malum/ho00014157#/buy-tickets-by-film-for-cinemas?at=2023-03-30&for-movie=ho00014157&view-mode=list" target="_blank">
                                <img src="@/assets/images/order/regal.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.readingcinemasus.com/townsquare/film/malum" target="_blank">
                                <img src="@/assets/images/order/reading.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.consolidatedtheatres.com/ward/film/malum" target="_blank">
                                <img src="@/assets/images/order/consolidated.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://drafthouse.com/show/malum" target="_blank">
                                <img src="@/assets/images/order/alamo.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.amctheatres.com/movies/malum-72498" target="_blank">
                                <img src="@/assets/images/order/amc.png"/>
                            </a>
                        </button>


                    </div>
                    <div v-if="dateMode!==1"  class="bugs">
                        <button class="bug">
                            <a href="https://www.vudu.com/content/movies/details/Malum/2338655" target="_blank">
                                <img src="@/assets/images/order/vudu.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://tv.apple.com/us/movie/malum/umc.cmc.4sijfua92jxbi1q9xg8byhjwd" target="_blank">
                                <img src="@/assets/images/order/apple.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://play.google.com/store/movies/details/Malum?id=bb_aXOrGyrw.P&hl=en&gl=US" target="_blank">
                                <img src="@/assets/images/order/google.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.amazon.com/placeholder_title-John-Doe/dp/B0B8MXT932/ref=sr_1_1?crid=3C0B5G599FDBL&keywords=malum&qid=1684886188&s=instant-video&sprefix=malum%2Cinstant-video%2C143&sr=1-1" target="_blank">
                                <img src="@/assets/images/order/amazon.png"/>
                            </a>
                        </button>

                        <!--
                        <button class="bug comingsoon">
                            <img src="@/assets/images/order/amazon.png"/>
                            <div class="comingsoon2">Coming Soon</div>
                        </button>
                        -->

                    </div>
                    <div class="hashtag">#FeedTheDemon</div>
                </div>
            </div>
            <div @click="closeAction" class="close-button-outer">
                <div class="close-button"></div>
            </div>
        </div>
    </section>
</template>

<script>
import gh from "@/helpers/gh"
import EmailSignup from '@/components/blocks/EmailSignup'

export default {
    name: "HomePage",
    components: {
        EmailSignup
    },
    data() {
        return {
            gh: gh,
            window: window,
            isOpen: false,
            dateMode:1
        }
    },
    mounted() {
        this.dateCodedLogic()
        this.window.addEventListener('keyup', this.escapeButtonPressed)
    },
    methods: {
        escapeButtonPressed(e) {
            if (e.key === "Escape") {
                this.closeAction()
            }
        },
        openAction() {
            this.isOpen = true
        },
        closeAction() {
            this.isOpen = false
        },

        dateCodedLogic() {
            // Set the countdown date and time
            const now = new Date().getTime()
            const firstDate = new Date("May 14, 2023 23:00:00 GMT-0700").getTime()
            const secondDate = new Date("May 15, 2023 23:00:00 GMT-0700").getTime()
            const difference = firstDate - now
            const difference2 = secondDate - now
            console.log('difference2, ', difference, difference2)
            if (difference<0) {
                this.dateMode = 2
            }
            if (difference2<0) {
                this.dateMode = 3
            }
        }
    }
}
</script>